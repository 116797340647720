import React from 'react';
import {Outlet} from 'react-router-dom';
import {Content, Theme, Grid, Column} from "@carbon/react";
import AppHeader from "../../components/AppHeader/AppHeader";

const PfsLayout = () => {

  return (
    <>
      <AppHeader mode={"pfs"}/>
      <Theme theme={"white"}>
        <Content style={{backgroundColor: '#eee', paddingTop: '50px', paddingBottom: '0px', minHeight: '100vh'}}>
          <Grid style={{backgroundColor: 'white', paddingTop: '50px', paddingBottom: '50px', minHeight: 'calc(100vh - 50px)'}}>
            <Column sm={4} md={8} lg={16}>
              <Outlet/>
            </Column>
          </Grid>
        </Content>
      </Theme>
    </>
  );
}

export default PfsLayout;
