import React from 'react';
import './App.css';
import {ContractPage} from "./pages/Pfs/ContractPage";
import {ContractPage as FamilienMedContractPage} from "./pages/FamilienMed/ContractPage";
import {InPersonContractPage as FamilienMedInPersonContractPage} from "./pages/FamilienMed/InPersonContractPage";
import {ContractPage as GenericTestContractPage} from "./pages/GenericTest/ContractPage";
import {InPersonContractPage as GenericTestInPersonContractPage} from "./pages/GenericTest/InPersonContractPage";
import {QueryClient, QueryClientProvider} from "react-query";
import {Routes, Route} from "react-router-dom";
import PfsLayout from "./layouts/PfsLayout";
import {StatusPage} from "./pages/Pfs/StatusPage";
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

const queryClient = new QueryClient();

function App() {
  const {i18n} = useTranslation();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path={'/pfs'} element={<PfsLayout/>}>
            <Route path={'contracts/start/:jwtToken'} element={<ContractPage/>}/>
            <Route path={'contracts/status/:jwtToken'} element={<StatusPage/>}/>
          </Route>
          <Route path={'/fm'} element={<PfsLayout/>}>
            <Route path={'contracts/start/:jwtToken'} element={<FamilienMedContractPage/>}/>
            <Route path={'contracts/in-person/start/:jwtToken'} element={<FamilienMedInPersonContractPage/>}/>
          </Route>
          {process.env.REACT_APP_ENV !== 'production' &&
          <Route path={'/test'} element={<PfsLayout/>}>
            <Route path={'contracts/start/:jwtToken'} element={<GenericTestContractPage/>}/>
            <Route path={'contracts/in-person/start/:jwtToken'} element={<GenericTestInPersonContractPage/>}/>
          </Route>
          }
        </Routes>

        <Helmet titleTemplate={'%s'} htmlAttributes={{lang: i18n.language}}>
          <title>Contracts</title>
        </Helmet>
      </QueryClientProvider>
    </>
  );
}

export default App;
