import React from 'react';
import {InlineNotification} from "@carbon/react";
import {useSearchParams} from "react-router-dom";
import {ContractStatusSimple} from "../../components/ContractStatusSimple";

export const StatusPage = () => {
  const [searchParams] = useSearchParams()

  return (
    <div style={{margin: '0 0 30px'}}>
      <h2 style={{margin: '0 0 30px'}}>PfS Contract Status Page</h2>

      {searchParams.get('event') === 'session_timeout' &&
      <InlineNotification key={`notification-event-session-timeout`} lowContrast={true} hideCloseButton={true} title={'Session timeout'} subtitle={'Session timeout'} style={{marginBottom: '1em'}}/>
      }
      {searchParams.get('event') === 'cancel' &&
      <InlineNotification key={`notification-event-session-timeout`} lowContrast={true} hideCloseButton={true} title={'Canceled'} subtitle={'The recipient canceled the signing operation'} style={{marginBottom: '1em'}}/>
      }
      {searchParams.get('event') === 'decline' &&
      <InlineNotification key={`notification-event-session-timeout`} lowContrast={true} hideCloseButton={true} title={'Declined'} subtitle={'The recipient decline to sign'} style={{marginBottom: '1em'}}/>
      }
      {searchParams.get('event') === 'exception' &&
      <InlineNotification key={`notification-event-session-timeout`} lowContrast={true} hideCloseButton={true} title={'Exception'} subtitle={'An exception occured'} style={{marginBottom: '1em'}}/>
      }
      {searchParams.get('event') === 'ttl_expired' &&
      <InlineNotification key={`notification-event-session-timeout`} lowContrast={true} hideCloseButton={true} title={'TTL expired'} subtitle={'The TTL, time to live, timer expired'} style={{marginBottom: '1em'}}/>
      }
      {searchParams.get('event') === 'signing_complete' &&
      <InlineNotification key={`notification-event-signing-complete`} lowContrast={true} hideCloseButton={true} title={'Signing complete'} subtitle={'Signer completed the signing ceremony'} kind={'success'} style={{marginBottom: '1em'}}/>
      }
      {searchParams.get('event') === 'viewing_complete' &&
      <InlineNotification key={`notification-event-viewing-complete`} lowContrast={true} hideCloseButton={true} title={'Viewing complete'} subtitle={'Recipient completed viewing the envelope'} kind={'info'} style={{marginBottom: '1em'}}/>
      }

      <ContractStatusSimple />
    </div>
  );
}
