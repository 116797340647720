import axios from 'axios';
import {IFormInputs} from "../../types/IFormInputs";
import {IInPersonFormInputs} from "../../types/IInPersonFormInputs";

const baseUrl = process.env.REACT_APP_ANFRAGE_API_BASE_URL;

const apiClient = axios.create({
  baseURL: baseUrl,
  responseType: 'json',
});

const api = {
  client: apiClient,

  pfsStart: (formData: IFormInputs, newJwtToken: string) => {
    const startUrl = `/api/v1/contracts/pfs/start/${newJwtToken}`;

    let data = Object.assign({}, formData) as any;
    return apiClient.post(startUrl, data);
  },

  pfsPrepareForm: (jwtToken: string) => {
    return apiClient.post(`/api/v1/contracts/pfs/prepare_form/${jwtToken}`);
  },

  pfsStatus: (jwtToken: string) => {
    return apiClient.post(`/api/v1/contracts/pfs/status/${jwtToken}`);
  },

  familienMedPrepareForm: (jwtToken: string, mode: string) => {
    return apiClient.post(`/api/v1/contracts/familien-med/prepare_form/${jwtToken}?mode=${mode}`);
  },

  familienMedStart: (formData: IFormInputs|IInPersonFormInputs, newJwtToken: string, mode: string) => {
    const startUrl = `/api/v1/contracts/familien-med/start/${newJwtToken}?mode=${mode}`;

    let data = Object.assign({}, formData) as any;
    return apiClient.post(startUrl, data);
  },

  genericTestPrepareForm: (jwtToken: string, mode: string) => {
    return apiClient.post(`/api/v1/contracts/generic_test/prepare_form/${jwtToken}?mode=${mode}`);
  },

  genericTestStart: (formData: IFormInputs|IInPersonFormInputs, newJwtToken: string, mode: string) => {
    const startUrl = `/api/v1/contracts/generic_test/start/${newJwtToken}?mode=${mode}`;

    let data = Object.assign({}, formData) as any;
    return apiClient.post(startUrl, data);
  },
};

export default api;
