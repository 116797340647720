/**
 * @see https://stackoverflow.com/questions/6393943/convert-a-javascript-string-in-dot-notation-into-an-object-reference
 * @param obj
 * @param path
 * @param value
 */
export const dotNotationSet = (obj: any, path: string | string[], value: any): void => {
  if (typeof path == 'string') {
    dotNotationSet(obj, path.split('.'), value);
    return;
  }

  if (path.length === 0) {
    return;
  }

  if (path.length === 1) {
    obj[path[0]] = value;
    return;
  }

  dotNotationSet(obj[path[0]], path.slice(1), value);
};
