import React from 'react';
import {Grid, Column, TextInput} from "@carbon/react";
import {useTranslation} from "react-i18next";
import {FormState, UseFormRegister} from "react-hook-form";
import {IInPersonFormInputs} from "../../../types/IInPersonFormInputs";

type Props = {
  prefix: 'customer' | 'service_recipient' | 'contact_person';
  register: UseFormRegister<IInPersonFormInputs>;
  formState: FormState<IInPersonFormInputs>;
  handleInputChange: (event: { target: any }) => void;
};

export const InPersonAddress = ({prefix, register, formState: {errors}, handleInputChange}: Props) => {
  const {t} = useTranslation();

  return (
    <React.Fragment>
      <Grid>
        <Column sm={2} md={4} lg={6} xlg={6}>
          <TextInput
            id={`${prefix}-lastname`}
            autoComplete={"nope"}
            labelText={t('form.address.lastname.label')}
            placeholder={t('form.address.lastname.placeholder')}
            invalid={!!errors[prefix]?.lastname}
            invalidText={errors[prefix]?.lastname?.message}
            {...register(`${prefix}.lastname`, {onChange: handleInputChange})}
          />
        </Column>
        <Column sm={2} md={4} lg={6} xlg={6}>
          <TextInput
            id={`${prefix}-firstname`}
            autoComplete={"nope"}
            labelText={t('form.address.firstname.label')}
            placeholder={t('form.address.firstname.placeholder')}
            invalid={!!errors[prefix]?.firstname}
            invalidText={errors[prefix]?.firstname?.message}
            {...register(`${prefix}.firstname`, {onChange: handleInputChange})}
          />
        </Column>
      </Grid>

      <Grid>
        <Column sm={4} md={3} lg={6} xlg={6}>
          <TextInput
            id={`${prefix}-street`}
            autoComplete={"nope"}
            labelText={t('form.address.street.label')}
            placeholder={t('form.address.street.placeholder')}
            invalid={!!errors[prefix]?.street}
            invalidText={errors[prefix]?.street?.message}
            {...register(`${prefix}.street`, {onChange: handleInputChange})}
          />
        </Column>
        <Column sm={2} md={3} lg={4} xlg={4}>
          <TextInput
            id={`${prefix}-location`}
            autoComplete={"nope"}
            labelText={t('form.address.location.label')}
            placeholder={t('form.address.location.placeholder')}
            invalid={!!errors[prefix]?.location}
            invalidText={errors[prefix]?.location?.message}
            {...register(`${prefix}.location`, {onChange: handleInputChange})}
          />
        </Column>
        <Column sm={2} md={2} lg={2} xlg={2}>
          <TextInput
            id={`${prefix}-postal-code`}
            autoComplete={"nope"}
            labelText={t('form.address.postal_code.label')}
            placeholder={t('form.address.postal_code.placeholder')}
            invalid={!!errors[prefix]?.postal_code}
            invalidText={errors[prefix]?.postal_code?.message}
            {...register(`${prefix}.postal_code`, {onChange: handleInputChange})}
          />
        </Column>
      </Grid>

      <Grid>
        <Column sm={2} md={4} lg={4} xlg={4}>
          <TextInput
            id={`${prefix}-phone-number`}
            autoComplete={"nope"}
            labelText={t('form.address.phone_number.label')}
            placeholder={t('form.address.phone_number.placeholder')}
            invalid={!!errors[prefix]?.phone_number}
            invalidText={errors[prefix]?.phone_number?.message}
            {...register(`${prefix}.phone_number`, {onChange: handleInputChange})}
          />
        </Column>
        <Column sm={2} md={4} lg={4} xlg={4}>
          <TextInput
            id={`${prefix}-mobile-number`}
            autoComplete={"nope"}
            labelText={t('form.address.mobile_number.label')}
            placeholder={t('form.address.mobile_number.placeholder')}
            invalid={!!errors[prefix]?.mobile_number}
            invalidText={errors[prefix]?.mobile_number?.message}
            {...register(`${prefix}.mobile_number`, {onChange: handleInputChange})}
          />
        </Column>
        <Column sm={4} md={4} lg={4} xlg={4}>
          <TextInput
            id={`${prefix}-email-address`}
            autoComplete={"nope"}
            labelText={t('form.address.email_address.label')}
            placeholder={t('form.address.email_address.placeholder')}
            invalid={!!errors[prefix]?.email_address}
            invalidText={errors[prefix]?.email_address?.message}
            {...register(`${prefix}.email_address`, {onChange: handleInputChange})}
          />
        </Column>
      </Grid>
    </React.Fragment>
  );
}
