import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationPl from './pl/translation.json';
import translationDe from './de/translation.json';

const resources = {
  pl: {
    translation: translationPl
  },
  de: {
    translation: translationDe
  }
} as const;

// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
i18n.use(LanguageDetector);

// pass the i18n instance to react-i18next.
i18n.use(initReactI18next);

/* options: https://www.i18next.com/overview/configuration-options */
i18n.init({
  debug: true,
  fallbackLng: 'dev',
  supportedLngs: ['de', 'pl', 'dev'],
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },

  resources,

  /* LanguageDetector options */
  detection: {
    // order and from where user language should be detected
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],

    // keys or params to lookup language from
    lookupQuerystring: 'lang',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLang',
    lookupSessionStorage: 'i18nextLang',

    // cache user language on
    caches: ['localStorage'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  }
});

export default i18n;
