import React from 'react';
import {LinkProps as RRLinkProps} from 'react-router-dom';
import {Header, HeaderGlobalAction, HeaderGlobalBar, HeaderName, HeaderNavigation, Link, SkipToContent, Theme, OverflowMenu, OverflowMenuItem} from "@carbon/react";
import {Notification, UserAvatar, EarthEuropeAfrica} from "@carbon/react/icons";
import {useTranslation} from "react-i18next";

type LocalProps = {
  mode: 'pfs' | 'aterima'
}

const languages: { [key: string]: { nativeName: string } } = {
  pl: {nativeName: 'Polish'},
  de: {nativeName: 'Deutsch'}
};

const AppHeader = (props: LocalProps) => {
  const {i18n} = useTranslation();
  return (
    <Theme theme={"g100"}>
      <Header aria-label="Carbon Tutorial">
        <SkipToContent/>
        <HeaderName<RRLinkProps> element={Link} to="/" prefix={"HCS | "}>
          Contracts
        </HeaderName>
        <HeaderNavigation aria-label="Navigation" aria-labelledby={"Nawigacja"}>
        </HeaderNavigation>
        <HeaderGlobalBar>
          {props.mode === 'aterima' &&
          <>
              <HeaderGlobalAction aria-label="Notifications">
                  <Notification size={20}/>
              </HeaderGlobalAction>
              <HeaderGlobalAction aria-label="Logout">
                  <UserAvatar size={20}/>
              </HeaderGlobalAction>
          </>
          }
          <OverflowMenu
            className={'cds--header__action'}
            flipped={true}
            renderIcon={() => <EarthEuropeAfrica size={20} className={'cds--overflow-menu__icon'} style={{width: 'auto', height: 'auto'}}/>}
            ariaLabel={'Languages list'}
          >
            {Object.keys(languages).map((lng) => (
              <OverflowMenuItem key={`lang-menu-item-${lng}`} itemText={languages[lng].nativeName} onClick={() => i18n.changeLanguage(lng)} style={{fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal'}}/>
            ))}
          </OverflowMenu>
        </HeaderGlobalBar>
      </Header>
    </Theme>
  );
}

export default AppHeader;
