import React, {useEffect, useState} from 'react';
import {Button, Form, Grid, Column, FormGroup, TextInput, NumberInput, Stack, Toggle, Loading, InlineNotification, ToastNotification} from "@carbon/react";
import {useTranslation} from "react-i18next";
import {Address} from "../../components/Form/Address";
import {useParams, useSearchParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {IFormInputs} from "../../types/IFormInputs";
import api from '../../services/api';
import {useIsFetching, useQuery, UseQueryResult} from "react-query";
import {dotNotationSet} from "../../functions/DotNotations";
import dot from "dot-object";
import {Helmet} from 'react-helmet';

const schema = yup.object({
  broker_seller: yup.object({
    email_address: yup.string().required().email(),
    lastname_and_firstname: yup.string().required(),
  }),
  contract_signer: yup.object({
    email_address: yup.string().required().email(),
    lastname_and_firstname: yup.string().required(),
  }),

  service: yup.object({
    price: yup.number().required().positive().integer().min(2000).max(10000).typeError('You must specify a number'),
  }),

  customer: yup.object({
    lastname: yup.string().required(),
    firstname: yup.string().required(),
    street: yup.string().required(),
    location: yup.string().required(),
    postal_code: yup.string().required(),
    email_address: yup.string().email(),
  }).required(),


  is_service_recipient: yup.number(),
  service_recipient: yup.object({
    lastname: yup.string(),
    firstname: yup.string(),
    street: yup.string(),
    location: yup.string(),
    postal_code: yup.string(),
    email_address: yup.string(),
  }).when('is_service_recipient', {
    is: (isServiceRecipient: any) => {
      return isServiceRecipient;
    },
    then: (schema) => {
      return schema.shape({
        lastname: yup.string().required(),
        firstname: yup.string().required(),
        street: yup.string().required(),
        location: yup.string().required(),
        postal_code: yup.string().required(),
        email_address: yup.string().email(),
      }).required();
    },
  })
}).required();

export const ContractPage = () => {
  const {t} = useTranslation();
  const [searchParams] = useSearchParams()
  const urlParams = useParams();

  const {register, handleSubmit, formState, reset: resetForm} = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });

  const [isServiceRecipientToggled, setServiceRecipientToggled] = useState<boolean>(false);
  const [isContactPersonToggled, setContactPersonToggled] = useState<boolean>(false);
  const [isSending, setSending] = useState<boolean>(false);
  const [httpMessages, setHttpMessages] = useState<[]>([]);
  const [newJwtToken, setNewJwtToken] = useState<string | null>(null);

  let defaultServicePrice: string = '';
  if (searchParams.has('service[price]')) {
    const val = searchParams.get('service[price]');
    if (typeof val === 'string') {
      const i = Number.parseInt(val);
      if (!Number.isNaN(i) && i > 0) {
        defaultServicePrice = i.toString();
      }
    }
  }

  const [formDefaultValue, setFormDefaultValue] = useState<IFormInputs>({
    broker_seller: {
      email_address: searchParams.get('broker_seller[email_address]') ?? '',
      lastname_and_firstname: searchParams.get('broker_seller[lastname_and_firstname]') ?? ''
    },
    contract_signer: {
      email_address: searchParams.get('contract_signer[email_address]') ?? '',
      lastname_and_firstname: searchParams.get('contract_signer[lastname_and_firstname]') ?? ''
    },
    service: {
      price: defaultServicePrice,
      admission_fee: undefined,
    },

    customer: {
      lastname: searchParams.get('customer[lastname]') ?? '',
      firstname: searchParams.get('customer[firstname]') ?? '',
      street: searchParams.get('customer[street]') ?? '',
      location: searchParams.get('customer[city]') ?? '',
      postal_code: searchParams.get('customer[postal_code]') ?? '',
      phone_number: searchParams.get('customer[phone_number]') ?? '',
      mobile_number: searchParams.get('customer[mobile_number]') ?? '',
      email_address: searchParams.get('customer[email_address]') ?? '',
    },

    is_service_recipient: isServiceRecipientToggled ? 1 : 0,

    service_recipient: {
      lastname: searchParams.get('service_recipient[lastname]') ?? '',
      firstname: searchParams.get('service_recipient[firstname]') ?? '',
      street: searchParams.get('service_recipient[street]') ?? '',
      location: searchParams.get('service_recipient[city]') ?? '',
      postal_code: searchParams.get('service_recipient[postal_code]') ?? '',
      phone_number: searchParams.get('service_recipient[phone_number]') ?? '',
      mobile_number: searchParams.get('service_recipient[mobile_number]') ?? '',
      email_address: searchParams.get('service_recipient[email_address]') ?? '',
    },
    contact_person: {
      lastname: searchParams.get('contact_person[lastname]') ?? '',
      firstname: searchParams.get('contact_person[firstname]') ?? '',
      street: searchParams.get('contact_person[street]') ?? '',
      location: searchParams.get('contact_person[city]') ?? '',
      postal_code: searchParams.get('contact_person[postal_code]') ?? '',
      phone_number: searchParams.get('contact_person[phone_number]') ?? '',
      mobile_number: searchParams.get('contact_person[mobile_number]') ?? '',
      email_address: searchParams.get('contact_person[email_address]') ?? '',
    }
  });

  const useBackendDefaultValues = (jwtToken: string | undefined): UseQueryResult<any, any> => {
    return useQuery(['defaultValues'], async () => {
        const {data} = await api.genericTestPrepareForm(jwtToken as string, 'remote-signing');
        return data;
      }, {
        refetchOnWindowFocus: false,
        retry: 0,
        enabled: (typeof jwtToken === 'string')
      }
    );
  };

  const isFetching = useIsFetching();

  let {jwtToken} = urlParams;
  let {data, isSuccess, error, isError} = useBackendDefaultValues(jwtToken);
  useEffect(() => {
    if (isSuccess && !!data) {
      if (data.result && data.result.form_defaults) {
        setFormDefaultValue((prevState) => {
          const resultArray = dot.dot(data.result.form_defaults);
          let copy = Object.assign({}, prevState);
          Object.keys(resultArray).forEach((key: string) => {
            const currentVal = dot.pick(key, prevState);
            if (typeof currentVal === 'undefined' || currentVal === null || currentVal === '') {
              /* Change value if current value is empty. */
              dotNotationSet(copy, key, resultArray[key]);
            }
          });
          return copy;
        });

        const masterKeys = [
          'service_recipient',
          'contact_person',
        ];

        masterKeys.forEach((masterKey: string) => {
          /* Is second address is needed? */
          const keys = [
            'firstname',
            'lastname',
            'street',
            'location',
            'postal_code',
            'phone_number',
            'mobile_number',
            'email_address'
          ];

          let is = false;
          keys.forEach((key: string) => {
            const currentVal = dot.pick(masterKey + '.' + key, data.result.form_defaults);
            if (typeof currentVal === 'string' && currentVal !== '') {
              is = true;
            }
          });

          if (masterKey === 'service_recipient') {
            if (!isServiceRecipientToggled && is) {
              setServiceRecipientToggled(true)
            }
          } else if (masterKey === 'contact_person') {
            if (!isContactPersonToggled && is) {
              setContactPersonToggled(true)
            }
          }
        });
      }

      if (data.result.new_jwt_token) {
        setNewJwtToken(data.result.new_jwt_token);
      }
    }
  }, [data, isSuccess, isContactPersonToggled, isServiceRecipientToggled]);

  const handleInputChange = (event: { target: any }) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setFormDefaultValue((prevState) => {
      let copy = Object.assign({}, prevState);
      dotNotationSet(copy, name, value);
      return copy;
    });
  }

  const onSubmit = async (data: IFormInputs, event: any) => {
    if (newJwtToken === null) {
      return;
    }

    setSending(true);

    try {
      const response = await api.genericTestStart(data, newJwtToken, 'remote-signing');
      if (response.data.result.url) {
        window.location.href = response.data.result.url;
      }
    } catch (error: any) {
      console.error(error);
      if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('messages') && Array.isArray(error.response.data.messages)) {
        setHttpMessages(error.response.data.messages)
      }
    }
    setSending(false);
  }

  useEffect(() => {
    resetForm(formDefaultValue);
  }, [formDefaultValue, resetForm]);

  if (isFetching) {
    return (<Loading active={!!(isFetching || isSending)} withOverlay={true}/>);
  }

  if (isError) {
    if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('messages') && Array.isArray(error.response.data.messages)) {
      return error.response.data.messages.map((message: any, index: number) => (
        <InlineNotification key={`notification-error-${index}`} lowContrast={true} hideCloseButton={true} title={message.title} subtitle={message.detail} style={{marginBottom: '1em'}}/>
      ));
    }

    return (<InlineNotification lowContrast={true} hideCloseButton={true} title={'Error'} subtitle={'Form preparation error [1]!'}/>);
  }

  if (!isSuccess) {
    if (data && data.hasOwnProperty('messages') && Array.isArray(data.message)) {
      return data.message.map((message: any) => (
        'a'
      ));
    }

    return (<InlineNotification lowContrast={true} hideCloseButton={true} title={'Error'} subtitle={'Form preparation error! [2]'}/>);
  }

  return (
    <>
      <Helmet>
        <title>{'Contract Form'}</title>
      </Helmet>
      <Loading active={!!(isFetching || isSending)} withOverlay={true}/>

      <div style={{margin: '0 0 30px'}}>
        <h2 style={{margin: '0 0 30px'}}>Contract Form</h2>
        <hr/>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off" style={{padding: '0 0 50px 0'}}>
        <Grid>
          <Column sm={4} md={8} lg={12}>
            <Stack gap={8}>
              <FormGroup legendText={t('form.broker_partner.legend')}>
                <Stack gap={4}>
                  <TextInput
                    id="broker-seller-lastname-and-firstname"
                    invalid={!!formState.errors.broker_seller?.lastname_and_firstname}
                    invalidText={formState.errors.broker_seller?.lastname_and_firstname?.message}
                    labelText={t('form.broker_partner.lastname_and_firstname.label')}
                    placeholder={t('form.broker_partner.lastname_and_firstname.placeholder')}
                    {...register('broker_seller.lastname_and_firstname', {onChange: handleInputChange})}
                  />

                  <TextInput
                    id="broker-seller-email-address"
                    type="email"
                    invalid={!!formState.errors.broker_seller?.email_address}
                    invalidText={formState.errors.broker_seller?.email_address?.message}
                    labelText={t('form.broker_partner.email_address.label')}
                    placeholder={t('form.broker_partner.email_address.placeholder')}
                    autoComplete={"nope"}
                    {...register('broker_seller.email_address', {onChange: handleInputChange})}
                  />
                </Stack>
              </FormGroup>

              <FormGroup legendText={t('form.contract_signer.legend')}>
                <Stack gap={4}>
                  <TextInput
                    id="contract-signer-lastname-and-firstname"
                    invalid={!!formState.errors.contract_signer?.lastname_and_firstname}
                    invalidText={formState.errors.contract_signer?.lastname_and_firstname?.message}
                    labelText={t('form.contract_signer.lastname_and_firstname.label')}
                    placeholder={t('form.contract_signer.lastname_and_firstname.placeholder')}
                    {...register('contract_signer.lastname_and_firstname', {onChange: handleInputChange})}
                  />
                  <TextInput
                    id="contract-signer-email-address"
                    invalid={!!formState.errors.contract_signer?.email_address}
                    invalidText={formState.errors.contract_signer?.email_address?.message}
                    labelText={t('form.contract_signer.email_address.label')}
                    placeholder={t('form.contract_signer.email_address.placeholder')}
                    autoComplete={"nope"}
                    {...register('contract_signer.email_address', {onChange: handleInputChange})}
                  />
                </Stack>
              </FormGroup>

              <FormGroup legendText={t('form.service.legend')}>
                <Stack gap={4}>
                  <Grid>
                    <Column sm={2} md={4} lg={6} xlg={6}>
                      <NumberInput
                        id="service-price"
                        hideSteppers={true}
                        invalid={!!formState.errors.service?.price}
                        invalidText={formState.errors.service?.price?.message}
                        label={t('form.service.price.label')}
                        helperText={t('form.service.price.helper')}
                        iconDescription={'choose a number'}
                        value={formDefaultValue.service.price}
                        {...register('service.price', {onChange: handleInputChange})}
                      />
                    </Column>
                  </Grid>
                </Stack>
              </FormGroup>

              <FormGroup legendText={t('form.customer.legend')}>
                <Stack gap={4}>
                  <Address prefix={'customer'} register={register} formState={formState} handleInputChange={handleInputChange}/>
                </Stack>
              </FormGroup>

              <Toggle
                id="toggle-1"
                labelText={t('form.toggle.customer_and_service_receiver_are_different')}
                labelA={t('common.no')}
                labelB={t('common.yes')}
                toggled={isServiceRecipientToggled}
                onToggle={() => {
                  setFormDefaultValue((prevState) => {
                    let copy = Object.assign({}, prevState);
                    copy.is_service_recipient = isServiceRecipientToggled ? 0 : 1;
                    return copy;
                  });

                  setServiceRecipientToggled(!isServiceRecipientToggled);
                }}
              />

              {isServiceRecipientToggled &&
              <FormGroup legendText={t('form.service_recipient.legend')}>
                  <Stack gap={4}>
                      <Address prefix={'service_recipient'} register={register} formState={formState} handleInputChange={handleInputChange}/>
                  </Stack>
              </FormGroup>
              }

              <Toggle
                id="toggle-contact-person"
                labelText={t('form.toggle.customer_and_contact_person_are_different')}
                labelA={t('common.no')}
                labelB={t('common.yes')}
                toggled={isContactPersonToggled}
                onToggle={() => setContactPersonToggled(!isContactPersonToggled)}
              />

              {isContactPersonToggled &&
              <FormGroup legendText={t('form.contact_person.legend')}>
                  <Stack gap={4}>
                      <Address prefix={'contact_person'} register={register} formState={formState} handleInputChange={handleInputChange}/>
                  </Stack>
              </FormGroup>
              }

              <Button type={'submit'}>
                {t('form.submit.title')}
              </Button>
            </Stack>
          </Column>
        </Grid>
      </Form>
      {httpMessages.length > 0 && httpMessages.map((message: any, index: number) => (
        <ToastNotification
          key={`http-message-error-${index}`}
          lowContrast={true}
          hideCloseButton={false}
          title={message.title}
          subtitle={message.detail}
          style={{marginBottom: '1em'}}
          className={'app-notify'}
          onClose={() => setHttpMessages([])}
        />
      ))}
    </>
  );
}
