import React, {useEffect, useState} from 'react';
import {useIsFetching, useQuery, UseQueryResult} from "react-query";
import api from "../../services/api";
import {Loading, InlineNotification, DataTable, TableContainer, Table, TableHead, TableRow, TableExpandHeader, TableHeader, TableBody, TableExpandRow, TableExpandedRow, TableCell, UnorderedList, ListItem} from "@carbon/react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface DataRowSigners {
  name: string;
  email: string;
  roleName: string;
}

interface DataRow {
  id: string;
  status: string;
  signers: DataRowSigners[];
  created: string;
}

export const ContractStatusSimple = () => {
  const isFetching = useIsFetching();
  const urlParams = useParams();
  const [pfsContractId, setPfsContractId] = useState<string|null>(null);
  const [tableRows, setTableRows] = useState<DataRow[]>([]);
  const {t} = useTranslation();

  let {jwtToken} = urlParams;

  const useQueryPfsStatus = (jwtToken: string | undefined): UseQueryResult<any, any> => {
    return useQuery(['pfsStatus'], async () => {
        const {data} = await api.pfsStatus(jwtToken as string);
        return data;
      }, {
        refetchOnWindowFocus: false,
        retryDelay: 1000,
        retry: 1,
        enabled: (typeof jwtToken === 'string')
      }
    );
  };

  let {data, isSuccess, isError} = useQueryPfsStatus(jwtToken);

  useEffect(() => {
    if (isSuccess && !!data) {
      if (data.result) {
        if (data.result.contract_id) {
          setPfsContractId(data.result.contract_id);
        }

        let rows: DataRow[] = [];
        if (data.result.envelopes && Array.isArray(data.result.envelopes)) {
          data.result.envelopes.forEach((value: any) => {
            let row: DataRow = {
              id: `${value.id}`,
              status: value.status as string,
              created: value.created as string,
              signers: value.signers as DataRowSigners[],
            };

            rows.push(row);
          });
        }

        setTableRows(rows);
      }
    }
  }, [data, isSuccess]);

  if (isFetching) {
    return (<Loading active={!!(isFetching)} withOverlay={true}/>);
  }

  if (isError) {
    return (<><InlineNotification key={`notification-event-session-timeout`} lowContrast={true} hideCloseButton={true} title={'Error'} subtitle={'Something went wrong'} style={{marginBottom: '1em'}}/></>);
  }

  const headers = [
    {
      key: 'id',
      header: t('statusTable.id.header'),
    },
    {
      key: 'status',
      header: t('statusTable.status.header'),
    },
    {
      key: 'created',
      header: t('statusTable.created.header'),
    },
  ];

  return (
    <div>
      <p>
        <a href={`https://${process.env.REACT_APP_PFS_DOMAIN}/contracts/${pfsContractId}`} target="_blank" rel="noreferrer">{`https://${process.env.REACT_APP_PFS_DOMAIN}/contracts/${pfsContractId}`}</a>
      </p>
      <hr/>
      <DataTable rows={tableRows} headers={headers}>
        {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getTableProps,
            getTableContainerProps,
          }: {rows: [{id: string, cells: [{id: string, value: string}]}], headers: [{header: string}], getHeaderProps: any, getRowProps: any, getTableProps: any, getTableContainerProps: any }) => (
          <TableContainer
            title="Envelopes"
            {...getTableContainerProps()}>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  <TableExpandHeader />
                  {headers.map((header, i) => (
                    <TableHeader key={i} {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <React.Fragment key={row.id}>
                    <TableExpandRow {...getRowProps({ row })}>
                      {row.cells.map((cell) => (
                        <TableCell key={cell.id}>{cell.value}</TableCell>
                      ))}
                    </TableExpandRow>
                    <TableExpandedRow colSpan={headers.length + 1}>
                      <h6>{t('common.signers')}:</h6>
                      <UnorderedList>
                      {tableRows[index].signers.map((signer) => (
                        <ListItem><strong>{signer.name}</strong> (email: {signer.email}, role: {signer.roleName})</ListItem>
                      ))}
                      </UnorderedList>
                    </TableExpandedRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </div>
  );
}
